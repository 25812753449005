import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import dashboardApi from '~/api/dashboardApi'
import serviceRequest from '~/app/serviceRequest'
import {DashBoardTableType} from '../components/dashboard-table/types/DashBoardTableType'

// !!! NOTE: Make sure to define serviceMethod in dashboardApi first

// OVERALL
export const getOverallTotal = createAsyncThunk('overall/getoverall', async (params: {year: number}, thunkAPI) => {
  const {year} = params
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: dashboardApi.getOverallTotal,
    payload: {year},
    options: {
      skipLoader: false,
    },
  })
})

export const getTableIncome = createAsyncThunk(
  'overall/gettableincome',
  async (params: {year: number; paid: boolean}, thunkAPI) => {
    const {year, paid} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getTableIncome,
      payload: {year, paid},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const getOverallTableClient = createAsyncThunk(
  'overall/gettableclient',
  async (params: {year: number}, thunkAPI) => {
    const {year} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOverallTableClient,
      payload: {year},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const getOverallTableClientDetail = createAsyncThunk(
  'overall/gettableclientdetail',
  async (params: {clientId: string; year: number}, thunkAPI) => {
    const {clientId, year} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOverallTableClientDetail,
      payload: {clientId, year},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const getLineChart = createAsyncThunk('overall/getlinechart', async (params: {year: number}, thunkAPI) => {
  const {year} = params
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: dashboardApi.getOverallLineChart,
    payload: {year},
    options: {
      skipLoader: false,
    },
  })
})

// INSPECTION & AUDIT ORDER
export const getOrderTotalPaidUnpaid = createAsyncThunk(
  'dashboard/getOrderTotalPaidUnpaid',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderTotalPaidUnpaid,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getOrderTotalPaidUnpaidByYear = createAsyncThunk(
  'dashboard/getOrderTotalPaidUnpaidByYear',
  async (params: {date: string}, thunkAPI) => {
    const {date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderTotalPaidUnpaidByYear,
      payload: {date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getOrderTable = createAsyncThunk(
  'dashboard/getOrderTable',
  async (params: {paid: boolean; year: boolean; date: string}, thunkAPI) => {
    const {paid, year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderTable,
      payload: {paid, year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getOrderChart = createAsyncThunk(
  'dashboard/getOrderChart',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderChart,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getOrderLineChart = createAsyncThunk(
  'dashboard/getOrderLineChart',
  async (params: {year: boolean; start: string; ennd: string}, thunkAPI) => {
    const {year, start, ennd} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderLineChart,
      payload: {year, start, ennd},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const getAuditTotalPaidUnpaid = createAsyncThunk(
  'dashboard/getAuditTotalPaidUnpaid',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditTotalPaidUnpaid,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getAuditTotalPaidUnpaidByYear = createAsyncThunk(
  'dashboard/getAuditTotalPaidUnpaidByYear',
  async (params: {date: string}, thunkAPI) => {
    const {date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditTotalPaidUnpaidByYear,
      payload: {date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getAuditTable = createAsyncThunk(
  'dashboard/getAuditTable',
  async (params: {paid: boolean; year: boolean; date: string}, thunkAPI) => {
    const {paid, year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditTable,
      payload: {paid, year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getAuditChart = createAsyncThunk(
  'dashboard/getAuditChart',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditChart,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getAuditLineChart = createAsyncThunk(
  'dashboard/getAuditLineChart',
  async (params: {year: boolean; start: string; ennd: string}, thunkAPI) => {
    const {year, start, ennd} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditLineChart,
      payload: {year, start, ennd},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const getTodayOverview = createAsyncThunk(
  'dashboard/getTodayOverview',
  async (data: unknown | null, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getTodayOverview,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getDayOverview = createAsyncThunk('dashboard/getDayOverview', async (params: {date: string}, thunkAPI) => {
  const {date} = params
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: dashboardApi.getDayOverview,
    payload: {date},
    options: {
      skipLoader: false,
    },
  })
})

export const getOrderOrAuditByCode = createAsyncThunk(
  'dashboard/getorderorauditbycode',
  async (code: string, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderOrAuditByCode,
      payload: code,
      options: {
        skipLoader: false,
      },
    })
  },
)

export type State = {
  loading: string
  data: DashBoardTableType[]
  error: string
  paginationData: {page: number; pageSize: number; totalPage: number}[]
}

const initialState: State = {
  loading: 'idle',
  data: [] as DashBoardTableType[],
  error: '',
  paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1}),
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    handleChangePage: (state, action) => {
      const {activePage, page} = action.payload
      state.paginationData[activePage].page = page
    },
    handleChangePageSize: (state, action) => {
      const {activePage, pageSize} = action.payload
      state.paginationData[activePage].pageSize = pageSize
    },
    handleChangeTotalPage: (state, action) => {
      const {activePage, totalPage = 1} = action.payload
      state.paginationData[activePage].totalPage = totalPage
    },
  },
  extraReducers: () => {},
})

const {reducer} = dashboard
export default reducer
