import {configureStore} from '@reduxjs/toolkit'
import orderReducer from '../pages/order-list/orderSlice'
import supplierReducer from '../pages/supplier-list/supplierSlice'
import inspectionReducer from '~/pages/inspection-order/inspectionSlide'
import paymentReducer from '~/pages/create-order/slide/paymentSlide'
import userReducer from '~/pages/user-list/userSlide'
import factoryReducer from '~/pages/factory-list/factorySlice'
import finalreportReducer from '~/pages/create-order/slide/finalReportSlide'
import attachmentReducer from '~/pages/create-order/slide/attachmentSilde'
import authenticateReducer from '~/pages/login/authenticateSlide'
import notificationReducer from '~/layouts/components/navbar/notificationSlide'
// import authReducer from "features/Auth/authSlice"
import loaderReducer from './loaderSlice'
import auditReducer from '~/pages/audit-detail/auditSlide'
import dashboardReducer from '~/pages/dashboard/slice/dashboardSlice'

const rootReducer = {
  order: orderReducer,
  // auth: authReducer,
  loader: loaderReducer,
  factory: factoryReducer,
  supplier: supplierReducer,
  inspection: inspectionReducer,
  payment: paymentReducer,
  user: userReducer,
  finalreport: finalreportReducer,
  attachment: attachmentReducer,
  authenticate: authenticateReducer,
  audit: auditReducer,
  notification: notificationReducer,
  dashboard: dashboardReducer,
  // login: loginReducer,
}

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
