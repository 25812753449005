import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import auditApi from '~/api/auditApi'
import serviceRequest from '~/app/serviceRequest'

const initialState = () => {
  return {
    paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1}),
  }
}

export const getAllAudit = createAsyncThunk('auditorder/getAll', async (data: unknown | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: auditApi.getAllAudit,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})
 
export const getAuditsByUserId = createAsyncThunk(
  'audit/getAuditsByUserId',
  async (data: unknown | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: auditApi.getAuditsByUserId,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)
 

export const createAudit = createAsyncThunk('auditorder/createaudit', async (data: unknown | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: auditApi.createAudit,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const updateAudit = createAsyncThunk('auditorder/updateaudit', async (payload: unknown | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: auditApi.updateAudit,
    payload: payload,
    options: {
      skipLoader: false,
    },
  })
})

export const deleteAudit = createAsyncThunk('auditorder/deleteaudit', async (id: string | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: auditApi.deleteAudit,
    payload: id,
    options: {
      skipLoader: false,
    },
  })
})

export const getAuditById = createAsyncThunk('auditorder/getauditById', async (id: string | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: auditApi.getAuditById,
    payload: id,
    options: {
      skipLoader: false,
    },
  })
})

export const getAuditsAllFilter = createAsyncThunk(
  'auditorder/getallfilter',
  async (data: unknown | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: auditApi.getAuditsAllFilter,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

export const updateAuditStatus = createAsyncThunk(
  'audit/updateAuditStatus',
  async (data: any | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: auditApi.updateStatus,
      payload: data,
      options: {
        skipLoader: false
      }
    })
  }
)

export const unlockAudit = createAsyncThunk(
  'audit/unlockAudit',
  async (data: any | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: auditApi.unlockAudit,
      payload: data,
      options: {
        skipLoader: false
      }
    })
  }
)


const audit = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    handleChangePage: (state, action) => {
      const {activePage, page} = action.payload
      state.paginationData[activePage].page = page
    },
    handleChangePageSize: (state, action) => {
      const {activePage, pageSize} = action.payload
      state.paginationData[activePage].pageSize = pageSize
    },
    handleChangeTotalPage: (state, action) => {
      const {activePage, totalPage = 1} = action.payload
      state.paginationData[activePage].totalPage = totalPage
    },
    extraReducers: () => {},
  },
})

const {reducer, actions} = audit
export const {handleChangePage, handleChangePageSize, handleChangeTotalPage} = actions
export default reducer
